import React, { useState,useEffect  } from 'react';

// -----------Importar Componentes de Terceros---------------------
import Typography from '@mui/material/Typography';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import IconButton from '@mui/material/IconButton';


import './styles.css';

const CarritoWeb = ({ CarritoFixed, StyleDragArea, FormatearComoMonedaMXN, SetNombreSeleccionado, FormActualizar, Step_PersonalizaSet, Step_Extra, ListTypo, Step_Pago, SubTotal, SubTotalExtra, StepLenght, HandleNext }) => {
  const [SwitchProductosIncluidos, setSwitchProductosIncluidos] = useState(false)
  const [SwitchServiciosIncluidos, setSwitchServiciosIncluidos] = useState(false)
  const [SwitchProductosExtras, setSwitchProductosExtras] = useState(true)

  const ShowProductosIncluidos = () => {
    setSwitchProductosIncluidos(!SwitchProductosIncluidos);
    setSwitchServiciosIncluidos(false);
    setSwitchProductosExtras(SwitchProductosExtras===false?SwitchProductosIncluidos?true:false:!SwitchProductosExtras);
  }

  const ShowServiciosIncluidos = () => {
    setSwitchProductosIncluidos(false);
    setSwitchServiciosIncluidos(!SwitchServiciosIncluidos);
    setSwitchProductosExtras(SwitchProductosExtras===false?SwitchServiciosIncluidos?true:false:!SwitchProductosExtras);
  }

  const ShowProductosExtras = () => {
    setSwitchProductosIncluidos(false);
    setSwitchServiciosIncluidos(false);
    setSwitchProductosExtras(!SwitchProductosExtras) ;
  }

  useEffect(() => {
    if (Step_Extra.ListCarritoExtras.length > 4) {
      setSwitchProductosExtras(false);
    }
  }, [Step_Extra.ListCarritoExtras.length]);

  return (
    <div style={{backgroundColor:'white', marginTop: CarritoFixed ? '28px' : '0px' }} className={CarritoFixed ? 'carrito' : 'carritoFixed'} >
      <Typography sx={{ fontSize: '16px', marginLeft: '10px', marginBottom: '5px' }} >Carrito de compra</Typography>

      <div id="divVelacion">
        <h5 style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontSize: '15px', marginBottom:'0px' }}>
          Set seleccionado: &nbsp; &nbsp;<ShoppingCartOutlinedIcon sx={{ fontSize: '23.6px', color: 'white' }} />
        </h5>
        <h6 className='mb-0' id="h6_set_seleccionado">
          {SetNombreSeleccionado}             {FormActualizar.TipoDespedida === '' ? '' : ListTypo.find((element) => (element.id === FormActualizar.TipoDespedida)).Nombre}
        </h6>

      </div>

      {
        Step_Extra.ListCarritoExtras.length > 0
          ?
          <div id="divCarrito" className="mt-2 text-start">
            {
              Step_PersonalizaSet.ListCarrito.length > 0
                ?
                <>
                  <h6 style={{ cursor: 'pointer',marginBottom:'0px' }} onClick={ShowProductosIncluidos}>Productos incluidos <IconButton sx={{padding:'0px !important'}} onClick={ShowProductosIncluidos} size="small"><KeyboardArrowDownIcon sx={{ fontSize: '25px', color: '#D14081', rotate: SwitchProductosIncluidos ? '180deg' : '0deg' }} /></IconButton></h6> 
                  {
                    SwitchProductosIncluidos
                      ?
                      <ul className='ListSeviciosIncluidos'>
                        {
                          Step_PersonalizaSet.ListCarrito.map((element, index) => {
                            let cantidadASumar = 0;
                            const productoEncontrado = Step_PersonalizaSet.ListProductos.find((producto) => (producto.producto === element.producto && producto.grupo === element.grupo));
                            if (productoEncontrado && productoEncontrado.valor_default === 1) {
                            } else {
                              const productosEnMismoGrupo = Step_PersonalizaSet.ListProductos.filter((producto) => (producto.grupo === element.grupo && producto.valor_default === 1));
                              cantidadASumar = element.precio_venta - productosEnMismoGrupo[0].precio_venta;
                            }
                            return (
                              <li key={index}>
                                <div className="row">


                                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'baseline' }} className="col-sm-12">
                                    <spam sty className={cantidadASumar !== 0 ? "c_rosa" : "c_negro"}>
                                      - {element.nombre_prodcto}
                                    </spam>
                                    {
                                      cantidadASumar !== 0
                                        ?
                                        <button disabled={Step_Pago.FormPago.PedidoCliente !== '' ? true : false} type="button" onClick={() => (Step_PersonalizaSet.EliminarCarrito(element))} style={{ display: 'flex', alignItems: 'center', padding: 0, borderColor: 'white', verticalAlign: 'bottom' }} className="btn btn-rosa elimina">
                                          <Typography fontSize='12px'>{cantidadASumar !== 0 ? `+${FormatearComoMonedaMXN(cantidadASumar)}` : ""}</Typography> <DeleteOutlineOutlinedIcon sx={{ fontSize: '20px' }} />
                                        </button>
                                        : <></>
                                    }
                                  </div>
                                </div>
                              </li>
                            )
                          })
                        }
                      </ul>
                      :
                      <></>
                  }

                  <h6 style={{ cursor: 'pointer', marginBottom:'0px' }} onClick={ShowServiciosIncluidos}>Servicios incluidos <IconButton sx={{padding:'0px !important'}} onClick={ShowServiciosIncluidos} size="small"><KeyboardArrowDownIcon sx={{ fontSize: '25px', color: '#D14081', rotate: SwitchServiciosIncluidos ? '180deg' : '0deg' }} /></IconButton></h6>
                  {
                    SwitchServiciosIncluidos
                      ?
                      <ul className='ListSeviciosIncluidos'>
                        <li>- Traslado de 50 km redondos</li>
                        <li>- Trámites legales</li>
                        <li>- Preservación</li>
                        <li>- Conmemoración</li>
                        <li>- Cremación</li>
                      </ul>
                      :
                      <></>
                  }

                  <div className="mt-0" style={{ display: 'flex', justifyContent: 'space-between' }} >
                    <h6 className="mb-0">
                      Subtotal:
                    </h6>
                    <h6 className="mb-0">{FormatearComoMonedaMXN(SubTotal)}</h6>
                  </div>

                </>
                : <></>
            }
          </div>
          :
          <div id="divCarrito" className="mt-2 text-start">
            {
              Step_PersonalizaSet.ListCarrito.length > 0
                ?
                <>
                  <h6 className='mb-0'>Productos incluidos</h6>
                  <ul className='ListSeviciosIncluidos'>
                    {
                      Step_PersonalizaSet.ListCarrito.map((element, index) => {
                        let cantidadASumar = 0;
                        const productoEncontrado = Step_PersonalizaSet.ListProductos.find((producto) => (producto.producto === element.producto && producto.grupo === element.grupo));
                        if (productoEncontrado && productoEncontrado.valor_default === 1) {
                        } else {
                          const productosEnMismoGrupo = Step_PersonalizaSet.ListProductos.filter((producto) => (producto.grupo === element.grupo && producto.valor_default === 1));
                          cantidadASumar = element.precio_venta - productosEnMismoGrupo[0].precio_venta;
                        }
                        return (
                         
                          <li key={index}>
                            <div className="row">


                              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'baseline' }} className="col-sm-12">
                                <spam sty className={cantidadASumar !== 0 ? "c_rosa" : "c_negro"}>
                                  - {element.nombre_prodcto}
                                </spam>
                                {
                                  cantidadASumar !== 0
                                    ?
                                    <button disabled={Step_Pago.FormPago.PedidoCliente !== '' ? true : false} type="button" onClick={() => (Step_PersonalizaSet.EliminarCarrito(element))} style={{ display: 'flex', alignItems: 'center', padding: 0, borderColor: 'white', verticalAlign: 'bottom' }} className="btn btn-rosa elimina">
                                      <Typography fontSize='12px'>{cantidadASumar !== 0 ? `+${FormatearComoMonedaMXN(cantidadASumar)}` : ""}</Typography> <DeleteOutlineOutlinedIcon sx={{ fontSize: '20px' }} />
                                    </button>
                                    : <></>
                                }
                              </div>
                            </div>
                          </li>
                        )
                      })
                    }
                  </ul>
                  <h6 style={{ marginBottom:'0px'}}>Servicios incluidos</h6>
                  <ul className='ListSeviciosIncluidos'>
                    <li>- Traslado de 50 km redondos</li>
                    <li>- Trámites legales</li>
                    <li>- Preservación</li>
                    <li>- Conmemoración</li>
                    <li>- Cremación</li>
                  </ul>
                  <div className="mt-0" style={{ display: 'flex', justifyContent: 'space-between' }} >
                    <h6 className="mb-0">
                      Subtotal:
                    </h6>
                    <h6 className="mb-0">{FormatearComoMonedaMXN(SubTotal)}</h6>
                  </div>

                </>
                : <></>
            }
          </div>
      }

      {
        Step_Extra.ListCarritoExtras.length > 0
          ?
          <div id="divCarrito_extras" className="mt-2 text-start">
            <>
              <h6 style={{ cursor: 'pointer', marginBottom:'0px' }} onClick={ShowProductosExtras}>Productos Extra <IconButton sx={{padding:'0px !important'}} onClick={ShowProductosExtras} size="small"><KeyboardArrowDownIcon sx={{ fontSize: '25px', color: '#D14081', rotate: SwitchProductosExtras ? '180deg' : '0deg' }} /></IconButton></h6>
              {
                SwitchProductosExtras
                  ?
                  <ul className='ListSeviciosIncluidos' >
                    {
                      Step_Extra.ListCarritoExtras.map((element, index) => {
                        return (
                          <li>
                            <div className="row">
                              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'baseline' }} className="col-sm-12">
                                <spam className="c_rosa">
                                  {`[${element.cantidad}] ${element.nombre_prodcto}`}
                                </spam>
                                <button disabled={Step_Pago.FormPago.PedidoCliente !== '' ? true : false} type="button" onClick={() => (Step_Extra.EliminarCarritoExtra(element))} className="btn btn-rosa elimina" style={{ display: 'flex', alignItems: 'center', padding: 0, borderColor: 'white', verticalAlign: 'bottom' }}>
                                  <Typography fontSize='12px'>{`+${FormatearComoMonedaMXN(element.precio_venta * element.cantidad)}`}</Typography> <DeleteOutlineOutlinedIcon sx={{ fontSize: '20px' }} />
                                </button>
                              </div>
                            </div>
                          </li>
                        )
                      })
                    }
                  </ul>
                  :
                  <></>
              }

              <div className="mt-0" style={{ display: 'flex', justifyContent: 'space-between' }} >
                <h6  className="mb-0">Subtotal:</h6><h6 className="mb-0">{FormatearComoMonedaMXN(SubTotalExtra)}</h6>
              </div>
            </>
          </div>
          :
          <></>
      }

      {
        Step_Pago.FormPago.Descuento > 0
          ?
          <div id="divCarrito_extras" className="mt-4 text-start">
            <>
              <h6 className='mb-0'>Descuentos</h6>
              <ul className='ListSeviciosIncluidos'>
                <li>- {Step_Pago.FormPago.FolioCupon}</li>
              </ul>
              <div className="mt-0" style={{ display: 'flex', justifyContent: 'space-between' }} >
                <h6 className="mb-0">Subtotal: </h6><h6 className="mb-0">-{FormatearComoMonedaMXN(Step_Pago.FormPago.Descuento)}</h6>
              </div>
            </>
          </div>
          :
          <></>
      }


      <div id="divTotal" style={{ display: 'flex', justifyContent: 'space-around' }} >
        <p className='mb-0'>Total:</p>
        <p className='mb-0' > {FormatearComoMonedaMXN(SubTotal + SubTotalExtra - Step_Pago.FormPago.Descuento)}</p>
      </div>
      {
        StepLenght > 1
          ?
          <></>
          :
          Step_PersonalizaSet.ListGrupos.filter((element) => (element.select === false)).length > 0 || Step_PersonalizaSet.ListGrupos.length === 0
            ?
            <></>
            :
            <StyleDragArea>
              <button disabled={Step_PersonalizaSet.ListCarrito.length > 0 ? false : true} style={{ width: '100%', display: 'block' }} id="btnSiguiente" onClick={() => { Step_Extra.ListProductosExtras.length === 0 ? Step_Extra.CargarProductosExtras() : HandleNext('StepLenght') }} className="btn btnAmarilloChicos g mt-2" >Siguiente</button>
            </StyleDragArea>
      }

    </div>
  );
}

export default CarritoWeb;
     