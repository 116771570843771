import React, { useState, useEffect, useRef } from 'react'
import axios from 'axios';
import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom';


// import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';

import './App.css';


import HeaderColumba from '../components/Header_Columba';
import FooterColumba from '../components/Footer_Columba';
import routes from '../routes/routes';
import Box from '@mui/material/Box';
import Conmemoraciones from '../pages/Conmemoraciones';
import SetsFunerarios from '../pages/Sets_funerarios';
import Seguimiento from '../pages/Seguimiento';

const UrlToken = 'https://preprodcolumba.prophet.mx/api/usuario/login/';
const datalogin = { email: "apicolumba@funeralescolumba.com.mx", password: "5M#j7^hBRXY#5Y!m" }

function App() {
  const [HideFoother, setHideFoother] = useState(false)
  const [HeightWindow, setHeightWindow] = useState(window.innerHeight)
  const [HeightPage, setHeightPage] = useState(0)

  const BodyRef = useRef();
  const FooterRef = useRef();
  const ResizeObserverRef = useRef(null);


  const handleResize = () => {
    setHeightWindow(window.innerHeight);

    if (BodyRef.current && FooterRef.current) {
      setHeightPage(BodyRef.current.offsetHeight + FooterRef.current.offsetHeight);
      // Aquí puedes hacer lo que necesites con la altura del footer
    }
  };

  const handleResizeClases = () => {
    setHeightWindow(window.innerHeight);
    if (BodyRef.current && FooterRef.current) {
      setHeightPage(BodyRef.current.offsetHeight + FooterRef.current.offsetHeight);
      // Aquí puedes hacer lo que necesites con la altura del footer
    }

  };



  
  useEffect(() => {
    window.addEventListener('resize', handleResizeClases);
    handleResizeClases(); // Llama a la función inicialmente para establecer la altura correcta

    const resizeObserver = new ResizeObserver(() => {
      handleResizeClases();
    });

    if (ResizeObserverRef.current) {
      resizeObserver.observe(ResizeObserverRef.current);
    }

    return () => {
      window.removeEventListener('resize', handleResizeClases);
      if (resizeObserver) {
        resizeObserver.disconnect();
      }
    };
  }, []);



  // useEffect(() => {
  //   const handleResize = () => {
  //     setHeightWindow(window.innerHeight);
  //     if (BodyRef.current && FooterRef.current) {
  //       setHeightPage(BodyRef.current.offsetHeight + FooterRef.current.offsetHeight);
  //       // Aquí puedes hacer lo que necesites con la altura del footer
  //     }
  //   };

  //   window.addEventListener('resize', handleResize);
  //   handleResize(); // Llama a la función inicialmente para establecer la altura correcta

  //   return () => {
  //     window.removeEventListener('resize', handleResize);
  //   };
  // }, []); // Las dependencias son vacías porque `footerRef` no cambiará


  const OcultarFoother = () => {
    setHideFoother(!HideFoother);
  }

  return (
    <BrowserRouter>
      <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column', alignContent: 'space-between', padding: '0px', margin: '0px' }} className="App">


        <div ref={BodyRef} >
          <Box sx={{ height: '20%' }}>
            <HeaderColumba ListLink={routes.Principales} />
          </Box>
          <div ref={ResizeObserverRef} sx={{ height: '55%' }}>
            <Routes>

              {
                routes.Principales.map((rute, index) => (
                  <Route key={index} path={rute.path} element={<rute.component  OcultarFoother={OcultarFoother} />} />
                ))
              }
               {
                routes.Secundarias.map((rute, index) => (
                  <Route key={index} path={rute.path} element={<rute.component  OcultarFoother={OcultarFoother} />} />
                ))
              }

              <Route
                path="/SetsFunerarios/Cobertura"
                element={<Navigate to={`/SetsFunerarios/Cobertura/1`} />}
              /> 

              {/* <Route key={100055445} path='/Conmemoraciones/:id' element={<Conmemoraciones   />} />
              <Route key={65195498498} path='/SetsFunerarios/Cobertura/:id' element={< SetsFunerarios  OcultarFoother={OcultarFoother} />} />
              <Route key={65195498498} path='/SetsFunerarios/PersonalizaSet' element={< SetsFunerarios  OcultarFoother={OcultarFoother} />} />
              <Route key={6519549534538498} path='/Seguimiento/:folio/:appaterno' element={< Seguimiento  />} /> */}
            </Routes>
          </div>
        </div>
        <Box mt={HeightWindow < HeightPage ? '30px' : `${HeightWindow - HeightPage}px`}></Box>
        <div ref={FooterRef}>
          <FooterColumba HideFoother={HideFoother} />
        </div>

      </Box>
    </BrowserRouter>

  );
}

export default App;
