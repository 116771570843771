// Importación de librerías y componentes necesarios
import axios from "axios";
import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';


import CarruselProductos from "../../../components/Carrusel_Productos";

// Componente funcional ContentModalAgregarCarrito
const ContentModalAgregarCarrito = ({ProductoDefault, Title, AgregarCarritoExtra, CarritoExtra, OnClickClose, ProductoSelecionado, OnClickAgregarCarrito, Token, ListCarrito, ListCarritoExtra = [], FormatearComoMonedaMXN, Styles }) => {
    // Definición de estados utilizando useState
    const [DescripcionProducto, setDescripcionProducto] = useState([]);
    const [resultado, setresulatdo] = useState('');
    const [cantidad, setcantidad] = useState(ListCarritoExtra.length > 0 ? ListCarritoExtra.find((item) => (item.producto === ProductoSelecionado.producto)) ? ListCarritoExtra.find((item) => (item.producto === ProductoSelecionado.producto)).cantidad : 0 : 0);

    // Efecto secundario para realizar una consulta cuando el componente se monta
    useEffect(() => {
        // Función asíncrona para consultar la descripción del producto
        const ConsultarDescripcion = async () => {
            try {
                // Configuración de la autorización en las cabeceras de la solicitud HTTP
                axios.defaults.headers.common["Authorization"] = Token;
                // Solicitud HTTP para obtener la descripción adicional del producto
                const respuesta = await axios.request("https://preprodcolumba.prophet.mx/api/Productos/DescripcionesAdicionales/" + ProductoSelecionado.producto.trim());
                // Actualización del estado con la respuesta obtenida
                setDescripcionProducto(respuesta)
            } catch (error) {
                // Manejo de errores
                setDescripcionProducto([])
            }
        }
        // Llamada a la función para consultar la descripción al montar el componente
        ConsultarDescripcion();
        // Actualización del estado 'resultado' con la búsqueda del producto en el carrito
        setresulatdo(ListCarrito.find((item) => item.producto === ProductoSelecionado.producto));
    }, []);

    // Renderizado del componente
console.log(                                    resultado );
console.log(ProductoSelecionado);
    return (
        <div style={{ margin: '0% 0% ', padding: '0px' }} className="row">
            <Typography textAlign='center' marginBottom='1rem' fontSize='20px' fontWeight='600 !important' variant="h5" color='#D14081 !important'>
                {Title}
            </Typography>
            {/* Sección izquierda con el slider de imágenes */}
            <div className="col-sm-6">
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                    {/* Slider principal */}

                    <CarruselProductos ListImagen={ProductoSelecionado.img} AspectRation='16/9' />


                </div>
            </div>
            {/* Sección derecha con la descripción y opciones de compra */}
            <div className="col-sm-6 mt-0 text-center" style={{ display: 'flex', flexWrap: 'wrap', alignContent: 'space-between' }}>
                <Box sx={{ width: '100%' }}>
                    <div className="col-sm-12 text-center">
                        <div><Typography textAlign='center' variant="h6" fontSize='16px' color="#D14081 !important">Descripción</Typography></div>
                    </div>

                    {/* Renderizado de la descripción del producto */}
                    {
                        DescripcionProducto.length > 0
                            ?
                            DescripcionProducto.map(element => (
                                <ul id="lista_descripcion">
                                    <li>{element.descripcion}</li>
                                </ul>
                            ))
                            :
                            <Typography fontSize='13px' textAlign='center'  >Sin descripción por el momento</Typography>
                    }

                    {/* Renderizado del precio y opciones de compra */}
                    <p style={{ fontSize: '24px' }}> {CarritoExtra === true ? 'Precio unitario:' + FormatearComoMonedaMXN(ProductoSelecionado.precio_venta) : ''}</p>
                </Box>

                <Box sx={{ width: '100%' }} >
                    {/* Renderizado de los botones de agregar al carrito */}
                    {
                        // Opciones específicas según el estado de CarritoExtra y resultado
                        CarritoExtra === true
                            ?
                            <div className="row" style={{ display: 'flex', justifyContent: 'space-between' }}> {/* Si es un producto extra */}
                                <div className="col-sm-3" >
                                </div>
                                <div className="col-sm-6" style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
                                    <IconButton disabled={cantidad === 0 ? true : false} type="button" onClick={() => (setcantidad(cantidad - 1))} sx={{ padding: '2px' }}><RemoveIcon sx={{ color: '#b0427e' }} /></IconButton >
                                    <input style={{ paddingLeft: '0px', paddingRight: '0px', width: '40px', textAlign: 'center' }} type="text" id="inCantidad" className="form-control gris" value={cantidad} />
                                    <IconButton type="button" onClick={() => (setcantidad(cantidad + 1))} sx={{ padding: '2px' }} ><AddIcon sx={{ color: '#b0427e' }} /></IconButton >

                                </div>
                                <div className="col-sm-3">
                                </div>

                                <div className="col-sm-12" style={{ marginTop: '0px' }}>
                                    <Styles>
                                        <button disabled={cantidad > 0 ? false : true} type="button" style={{ marginTop: '5px', width: '100%' }} id="btnAgregaCarrito" onClick={() => (AgregarCarritoExtra(ProductoSelecionado, cantidad))} className="btn btnAmarilloChicos">
                                            Añadir producto: {FormatearComoMonedaMXN(ProductoSelecionado.precio_venta * cantidad)}
                                        </button>
                                    </Styles>
                                </div>
                            </div>
                            : // Si es un producto no extra
                            ProductoSelecionado.valor_default === 1 
                                ?
                                <>
                                    <div className="col-sm-12">
                                        <button type="button" style={{ marginTop: '10px', width: '100%' }} className="btn btn-secondary" disabled>Incluido en el set</button>
                                    </div>
                                    <div className="col-sm-12" style={{ marginTop: '7px' }}>
                                        <Styles>
                                            <button type="button" style={{ marginTop: '0px', width: '100%' }} id="btnConfirmar" onClick={() => (OnClickAgregarCarrito(ProductoSelecionado))} className="btn btnAmarilloChicos">Añadir producto</button>
                                        </Styles>
                                    </div>
                                </>
                                : // Si es un producto adicional
                                ProductoSelecionado.valor_default === 0 && resultado === undefined
                                    ?
                                    <div className="row" sty >
                                        <Box className="col-9" sx={{ paddingRight: '6px !important' }}>
                                            <Styles>
                                                <button style={{ padding: '7.5px 12px', marginTop: '10px', width: '100%' }} type="button" className="btn setfunerario btn-secondary" disabled>
                                                    Adicional {FormatearComoMonedaMXN(ProductoSelecionado.precio_venta - ProductoDefault.precio_venta)}
                                                </button>
                                            </Styles>
                                        </Box>
                                        <Box className="col-3" sx={{ paddingLeft: '0px !important' }}>
                                            <Styles>
                                                <button type="button" style={{ marginTop: '10px', width: '100%' }} id="btnConfirmar" onClick={() => (OnClickAgregarCarrito(ProductoSelecionado))} className="btn btn-secondary  btnAmarilloChicos">
                                                    <ShoppingCartOutlinedIcon sx={{ color: 'white' }} />
                                                </button>
                                            </Styles>
                                        </Box>
                                    </div>
                                    : // Si el producto ya está en el carrito
                                    resultado !== undefined
                                        ?
                                        <>
                                            <div className="col-sm-12">
                                                <Styles>
                                                    <button onClick={() => (OnClickClose(ProductoSelecionado))} type="button" style={{ marginTop: '10px', width: '250px' }} id="btnConfirmar" className="btn btnAmarilloChicos">
                                                        Producto agregado ✔
                                                    </button>
                                                </Styles>
                                            </div>
                                        </>
                                        : // Renderizado vacío si ninguna condición es verdadera
                                        <></>
                    }
                </Box>
            </div>
        </div>
    );
}

// Exportación del componente ContentModalAgregarCarrito
export default ContentModalAgregarCarrito;
