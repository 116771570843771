//-------------Importar librerias----------------------------
import React, { Component } from "react";
import axios from "axios";
// import { useInView  } from 'react-intersection-observer';
import { Navigate } from "react-router-dom";
import withRouter from "../../routes/withRouter";
import TodoDataService from '../../hooks/services'
import StyleDragArea from "./css/buttonindex";

// -----------Importar Componentes de Terceros---------------------
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

// -----------Importar Componentes Personalizados------------------
import CoberturaSets from "./Cobertura";
import PersonalizaSets from "./Personaliza/PersonalizaSet";

// -----------Importar Archivos Estaticos------------------
import './css/main.css'
import './css/sets.css'
import Ataud_1 from '../../assets/Imagenes/Sets/Atud/ataud 1.png'
import Ataud_2 from '../../assets/Imagenes/Sets/Atud/ataud 2.png'
import Ataud_3 from '../../assets/Imagenes/Sets/Atud/ataud 3.png'

import Cafe_1 from '../../assets/Imagenes/Sets/Cafe/cafe 1.png'
import Cafe_2 from '../../assets/Imagenes/Sets/Cafe/cafe 2.png'
import Cafe_3 from '../../assets/Imagenes/Sets/Cafe/cafe 3.png'

import CubreCaja_1 from '../../assets/Imagenes/Sets/CubreCaja/CubreCaja1.png'
import CubreCaja_2 from '../../assets/Imagenes/Sets/CubreCaja/CubreCaja2.png'
import CubreCaja_3 from '../../assets/Imagenes/Sets/CubreCaja/CubreCaja3.png'

import Carroza_1 from '../../assets/Imagenes/Sets/Carroza/Carroza1.png'
import Carroza_2 from '../../assets/Imagenes/Sets/Carroza/Carroza2.png'
import Carroza_3 from '../../assets/Imagenes/Sets/Carroza/Carroza3.png'

import Foto_1 from '../../assets/Imagenes/Sets/Foto/Foto1.png'
import Foto_2 from '../../assets/Imagenes/Sets/Foto/Foto2.png'
import Foto_3 from '../../assets/Imagenes/Sets/Foto/Foto3.png'

import Taco_1 from '../../assets/Imagenes/Sets/Tacos/Taco1.png'
import Taco_2 from '../../assets/Imagenes/Sets/Tacos/Taco2.png'
import Taco_3 from '../../assets/Imagenes/Sets/Tacos/Taco3.png'
import { Route } from "react-router-dom";

// ------------------------Valores Constantes----------------------------
const UrlValidarCupon = TodoDataService.GetHostRestApi() + '/Cupon/'
const UrlToken = TodoDataService.GetHostRestApi() + '/usuario/login/';
const UrlGetPedidoCliente = TodoDataService.GetHostRestApi() + '/PedidoCliente/'
const datalogin = { email: "apicolumba@funeralescolumba.com.mx", password: "5M#j7^hBRXY#5Y!m" }
const ListSets = [
  {
    "id": "1",
    "nombre": "Velación en sala",
    "precio": "16,000.00",
    "beneficios": ["Traslados", "Ataúd*", "Sala 24 horas", "Trámites"]
  },
  {
    "id": "2",
    "nombre": "Velación en casa",
    "precio": "11,500.00",
    "beneficios": ["Traslados", "Ataúd*", "Equipo de velación", "Trámites"]
  }, {
    "id": "3",
    "nombre": "Servicio directo",
    "precio": "11,500.00",
    "beneficios": ["Traslados", "Ataúd*", "Trámites"]
  }]

const ListCombos = { 11: 1, 12: 2, 21: 3, 22: 4, 31: 5, 32: 5 }
const ListImagenes = [Ataud_1, Ataud_2, Ataud_3]
const CatalogoImagenesProductos = { 93: [Ataud_1, Ataud_1, Ataud_1], 525: [Carroza_1, Carroza_1, Carroza_1], 605: [Carroza_2, Carroza_2, Carroza_2], 607: [Carroza_3, Carroza_3, Carroza_3], 1061: [CubreCaja_1, CubreCaja_1, CubreCaja_1], 1062: [CubreCaja_2, CubreCaja_2, CubreCaja_2], 1063: [CubreCaja_3, CubreCaja_3, CubreCaja_3], 1064: [Foto_1, Foto_1, Foto_1], 1065: [Foto_2, Foto_2, Foto_2], 1066: [Foto_3, Foto_3, Foto_3], 1067: [Cafe_1, Cafe_1, Cafe_1], 1068: [Cafe_2, Cafe_2, Cafe_2], 1069: [Cafe_3, Cafe_3, Cafe_3], 1070: [Taco_1, Taco_1, Taco_1], 1071: [Taco_2, Taco_2, Taco_2], 1072: [Taco_3, Taco_3, Taco_3] }
const ListImagenesExtra = [Cafe_1, Cafe_2, Cafe_3]



class SetsFunerarios extends Component {
  state = {
    DetalleSetsFuenerarios: false,
    DataDetalleDetalleSetsFuenerarios: { Estado: { nombre: '' }, Municipio: { nombre: '' }, Colonia: { nombre: '' } },
    Route: null,
    TotalServicioDespedida: 0,
    StepLenght: 0,
    Switch: '',
    Token: '',
    ShowAgregarCarrito: false,
    ShowAgregarCarritoExtra: false,
    ProductoSelecionado: '',
    ProductoSelecionadoExtra: '',
    DataSets: [],
    DataEstado: [],
    DataMunicipio: [],
    DataColonia: [],
    ListProductos: [],
    ListGrupos: [],
    ListCarrito: [],
    ListProductosExtra: [],
    ListGruposExtra: [],
    ListCarritoExtra: [],
    Total: '',
    SubTotal: 0,
    SubTotalExtra: 0,
    Descuento: 0,
    Cobertura: false,
    PerzonalizaSets: false,
    ModalConsultarCodigoPostal: false,
    SetSelecionado: '',
    formCobertura: {
      Estado: '',
      Municipio: '',
      Colonia: '',
      CodigoPostal: ''
    },
    form: {
      TipoVelacion: '',
      TipoDespedida: '',
    },
    FormFinado: {
      NombreFinado: '',
      ApellidoPaternoFinado: '',
      ApellidoMaternoFinado: '',
      Gernero: '',
      FechaNacimiento: '',
      Conmemoracion: false,
      NombreLugar: '',
      Direccion: '',
      CodigoPostal: '',
      Estado: '',
      Municipio: '',
      Colonia: '',
      Referencias: '',
      UsoInformacion: false,
      Terminos: false
    },
    FormResponsable: {
      Nombre: '',
      ApellidoPaterno: '',
      ApellidoMaterno: '',
      TelefonoPrimario: '',
      TelefonoSecundario: '',
      Email: '',
      EmailConfirmar: ''
    },
    FormPago: {
      KeyCliente: '',
      Tipo: '',
      TipoContacto: '',
      FolioCupon: '',
      CuponValido: false,
      Error: [],
      PedidoCliente: '',
      Descuento: 0,
      DataTransaccion: '',
      DataValidacionTransaccion: ''
    },
    ListHeadersStep: [
      { id: 0, Label: 'Personaliza tu set', Title: 'Personaliza tu set funerario', Complect: false },
      { id: 1, Label: 'Extra', Title: 'Personaliza tus extras', Complect: false },
      { id: 2, Label: 'Datos de contacto', Title: 'Datos de contacto', Complect: false },
      { id: 3, Label: 'Pago', Title: 'Pago', Complect: false },
      { id: 4, Label: 'Confirmacion', Title: 'Confirmacion', Complect: false }]
  }


  SeleccionarSet = (item) => {
    this.setState({ SetSelecionado: item, Cobertura: true, Route: '/SetsFunerarios/Cobertura/' + item })
  }

  CompletarStepAll = () => {
    let NewListHeadersStep = this.state.ListHeadersStep.map(element => ({
      ...element,
      Complect: true
    }));
    this.setState({ ListHeadersStep: NewListHeadersStep });
  }

  CompletarStep = () => {
    let NewListHeadersStep = this.state.ListHeadersStep.slice();
    NewListHeadersStep[this.state.StepLenght].Complect = true;
    this.setState({ ListHeadersStep: NewListHeadersStep });

  }


  ShowModalConsultarCodigoPostal = () => {
    this.setState({
      ModalConsultarCodigoPostal: !this.state.ModalConsultarCodigoPostal,
      DataMunicipio: [],
      DataColonia: [], formCobertura: { Estado: '1', Municipio: '', Colonia: '', CodigoPostal: this.state.formCobertura.CodigoPostal }
    })
  }


  OnClickShowAgregarCarrito = (producto) => {
    this.setState({ ShowAgregarCarrito: !this.state.ShowAgregarCarrito, ProductoSelecionado: producto });
  }

  OnClickShowAgregarCarritoExtra = (producto) => {
    this.setState({ ShowAgregarCarritoExtra: !this.state.ShowAgregarCarritoExtra, ProductoSelecionadoExtra: producto });
  }


  ActualizarGrupo = (producto, key) => {
    let listgrupo = this.state[key].map((item) => {
      if (item.grupo === producto.grupo.trim()) {
        item.select = true
      }
      return item
    }
    )
    return listgrupo;
  }


  PeticionProductosGet = async (Url) => {
    axios.defaults.headers.common["Authorization"] = this.state.Token;
    await axios.request(Url).then(response => {
      let productos = [];
      let grupos = [];
      let carrito = [];
      let Total = 0;
      response.data.forEach((element) => {
        productos.push({
          producto: element.producto.trim(),
          grupo: element.grupo.trim(),
          nombre_grupo: element.grupo_Nombre.trim().charAt(0).toUpperCase() + element.grupo_Nombre.trim().slice(1).toLowerCase(),
          nombre: element.nombre.trim().charAt(0).toUpperCase() + element.nombre.trim().slice(1).toLowerCase(),
          precio_venta: element.precio_venta,
          precio: element.precio_venta_sin_iva,
          combo: element.combo,
          valor_default: element.valor_default,
          img: CatalogoImagenesProductos[element.producto.trim()] ? CatalogoImagenesProductos[element.producto.trim()] : ListImagenes
        });
        if (!grupos.some((grupo) => grupo.grupo === element.grupo)) {
          grupos.push({
            grupo: element.grupo.trim(),
            nombre_grupo: element.grupo_Nombre.trim().charAt(0).toUpperCase() + element.grupo_Nombre.trim().slice(1).toLowerCase(),
            select: false
          });
        }
      })
      carrito = productos.filter((item) => (item.valor_default === 1)).map((element) => {
        Total += element.precio_venta
        return ({
          producto: element.producto.trim(),
          nombre_prodcto:  element.nombre.trim().charAt(0).toUpperCase() + element.nombre.trim().slice(1).toLowerCase(),
          precio_venta: element.precio_venta,
          cantidad: 1,
          combo: element.combo.trim(),
          grupo: element.grupo.trim(),
          notas: "",
        })
      })
      this.setState({ ListProductos: productos, ListGrupos: grupos, ListCarrito: carrito, SubTotal: Total, TotalServicioDespedida: Total })
    }).catch(error => {
      console.log(error.message);
    })
  }


  PeticionProductosExtrasGet = async () => {
    axios.defaults.headers.common["Authorization"] = this.state.Token;
    await axios.request('https://preprodcolumba.prophet.mx/api/establecimiento/productos/2').then(response => {
      let productosextra = [];
      let gruposextra = [];
      response.data.forEach((element) => {
        element.productos.forEach((producto) => {
          productosextra.push({
            producto: producto.productoId.trim(),
            nombre: producto.nombre.trim().charAt(0).toUpperCase() + producto.nombre.trim().slice(1).toLowerCase(),
            precio_venta: producto.precioVenta,
            grupo: element.familiaId.trim(),
            nombre_grupo: element.nombre.trim().charAt(0).toUpperCase() + producto.nombre.trim().slice(1).toLowerCase(),
            img: CatalogoImagenesProductos[producto.productoId.trim()] ? CatalogoImagenesProductos[producto.productoId.trim()] : ListImagenesExtra
          });

          if (!gruposextra.some((grupo) => grupo.grupo === element.familiaId.trim())) {
            gruposextra.push({
              grupo: element.familiaId.trim(),
              nombre_grupo: element.nombre.trim().charAt(0).toUpperCase() + element.nombre.trim().slice(1).toLowerCase(),
              select: false
            });
          }

        })
      })
      this.setState({ ListProductosExtra: productosextra, ListGruposExtra: gruposextra })
    }).catch(error => {
      console.log(error.message);
    })
    this.handleNext('StepLenght');
  }


  AgregarCarrito = (producto) => {
    let listcarrito = []
    const buscarGrupoProductos = this.state.ListCarrito.find((item) => item.grupo === producto.grupo);
    if (buscarGrupoProductos !== undefined)
      listcarrito = this.state.ListCarrito.filter(
        (item) => item.grupo !== buscarGrupoProductos.grupo
      );

    listcarrito.push({
      producto: producto.producto.trim(),
      nombre_prodcto: producto.nombre.trim(),
      precio_venta: producto.precio_venta,
      cantidad: 1,
      combo: producto.combo.trim(),
      grupo: producto.grupo.trim(),
      notas: "",
    });

    console.log(producto.nombre.trim());

    let total = 0
    listcarrito.forEach((element) => { total += element.precio_venta })

    let listgrupos = this.ActualizarGrupo(producto, 'ListGrupos');
    this.setState({ ListCarrito: listcarrito, SubTotal: total, ListGrupos: listgrupos })
    this.OnClickShowAgregarCarrito('');
  };

  AgregarCarritoExtra = (producto, cantidadP) => {
    let listcarritoextra = []
    listcarritoextra = this.state.ListCarritoExtra.filter(
      (item) => item.producto !== producto.producto
    );

    listcarritoextra.push({
      producto: producto.producto.trim(),
      nombre_prodcto: producto.nombre.trim(),
      precio_venta: producto.precio_venta,
      cantidad: parseInt(cantidadP),
      combo: this.state.ListCarrito[0].combo,
      grupo: producto.grupo.trim(),
      notas: "",
    });

    let total = 0
    listcarritoextra.forEach((element) => { total += (element.precio_venta * element.cantidad) })

    let listgruposextra = this.ActualizarGrupo(producto, 'ListGruposExtra');
    this.setState({ ListCarritoExtra: listcarritoextra, SubTotalExtra: total, ListGruposExtra: listgruposextra })
    this.OnClickShowAgregarCarritoExtra('');
  };


  EliminarCarrito = (producto) => {
    let listcarrito = []
    const productodefault = this.state.ListProductos.find(element => element.grupo === producto.grupo && element.valor_default === 1);

    listcarrito = this.state.ListCarrito.filter(
      (item) => item.producto !== producto.producto
    );

    listcarrito.push({
      producto: productodefault.producto.trim(),
      nombre_prodcto: productodefault.nombre.trim(),
      precio_venta: productodefault.precio_venta,
      cantidad: 1,
      combo: productodefault.combo.trim(),
      grupo: productodefault.grupo.trim(),
      notas: "",
    });

    let total = 0
    listcarrito.forEach((element) => { total += element.precio_venta })

    this.setState({ ListCarrito: listcarrito, SubTotal: total })
  };

  EliminarCarritoExtra = (producto) => {
    let listcarritoextra = []

    listcarritoextra = this.state.ListCarritoExtra.filter(
      (item) => item.producto !== producto.producto
    );
    let total = 0
    listcarritoextra.forEach((element) => { total += (element.precio_venta * element.cantidad) })

    this.setState({ ListCarritoExtra: listcarritoextra, SubTotalExtra: total })
  };

  SwitchHandleChange = (item) => {
    this.setState({ Switch: item })
  }

  ShowCobertura = () => {
    this.setState({ Cobertura: !this.state.Cobertura })
  }

  handleChange = async (e) => {
    await this.setState({
      [this.state.Switch]: {
        ...this.state[this.state.Switch],
        [e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value
      }
    });
  }

  handleChangeSingle = (Key, Name, Item) => {
    this.setState({
      [Key]: {
        ...this.state[Key],
        [Name]: Item
      }
    });
  }


  PeticionGet = async (Url, SetData) => {
    axios.defaults.headers.common["Authorization"] = this.state.Token;
    await axios.request(Url).then(response => {
      this.setState({ [SetData]: response.data })
    }).catch(error => {
      console.log(error);
    })
  };

  PeticionGetHome = async (Url, SetData) => {
    axios.defaults.headers.common["Authorization"] = await this.GetPeticionToken();
    await axios.request(Url).then(response => {
      this.setState({ [SetData]: response.data })
    }).catch(error => {
      console.log(error);
    })
  };


  HandleChangeCobertura = async (e) => {

    await this.handleChange(e);
    if (e.target.name === 'Estado') {
      this.setState({ formCobertura: { ...this.state.formCobertura, Municipio: '', Colonia: '', CidigoPostal: '' } });
      await this.PeticionGet(TodoDataService.GetHostRestApi() + '/Municipios/' + this.state.formCobertura.Estado, 'DataMunicipio');
    }
    if (e.target.name === 'Municipio') {
      this.setState({ formCobertura: { ...this.state.formCobertura, Colonia: '', CidigoPostal: '' } });
      await this.PeticionGet(TodoDataService.GetHostRestApi() + '/Asentamientos/municipio/' + this.state.formCobertura.Municipio, 'DataColonia');
    }
    if (e.target.name === 'Colonia') {
      this.setState({ formCobertura: { ...this.state.formCobertura, CodigoPostal: this.state.DataColonia.find((element) => (element.asentamientoId.trim() === this.state.formCobertura.Colonia)).cp } });
    }
  }


  HandleChangeCoberturaFinado = async (e) => {

    await this.handleChange(e);
    if (e.target.name === 'Estado') {
      this.setState({ FormFinado: { ...this.state.FormFinado, Municipio: '', Colonia: '', CidigoPostal: '' } });
      await this.PeticionGet(TodoDataService.GetHostRestApi() + '/Municipios/' + this.state.FormFinado.Estado, 'DataMunicipio');
    }
    if (e.target.name === 'Municipio') {
      this.setState({ FormFinado: { ...this.state.FormFinado, Colonia: '', CidigoPostal: '' } });
      await this.PeticionGet(TodoDataService.GetHostRestApi() + '/Asentamientos/municipio/' + this.state.FormFinado.Municipio, 'DataColonia');
    }
    if (e.target.name === 'Colonia') {
      this.setState({ FormFinado: { ...this.state.FormFinado, CodigoPostal: this.state.DataColonia.find((element) => (element.asentamientoId.trim() === this.state.FormFinado.Colonia)).cp } });
    }
  }

  SeleccionarDespedida = (Item) => {
    this.handleChangeSingle('form', 'TipoDespedida', Item)
    let Combo = ListCombos[this.state.SetSelecionado.concat(Item)]
    this.PeticionProductosGet(TodoDataService.GetHostRestApi() + `/Sets/productos/2/${Combo}/0021`);
  }

  ShowPersonalizaSets = () => {
    this.setState({ PerzonalizaSets: !this.state.PerzonalizaSets, Route: null })
  }

  GetPeticionToken = async () => {
    let result = '';
    await axios.post(UrlToken, datalogin).then(response => {
      this.setState({ Token: 'Bearer ' + response.data.token })
      result = 'Bearer ' + response.data.token
    }).catch(error => {
      console.log(error);
    });
    return result
  }

  ClosePersonalizarSet = () => {
    this.setState({
      PerzonalizaSets: false, ListCarrito: [], ListProductos: [], ListGrupos: [], ModalConsultarCodigoPostal: false, ProductoSelecionado: '', StepLenght: 0, SubTotal: 0, ShowAgregarCarrito: false, formCobertura: {
        Estado: '',
        Municipio: '',
        Colonia: '',
        CodigoPostal: ''
      }
    })
  }


  handleNext = (key) => {
    this.CompletarStep();
    this.setState({ [key]: this.state[key] + 1 });
  };

  handleBack = (key) => {
    this.setState({ [key]: this.state[key] - 1 });
  };

  PeticionGetPedidoCliente = async (id) => {
    try {
      axios.defaults.headers.common["Authorization"] = await this.GetPeticionToken();
      const response = await axios.request(UrlGetPedidoCliente + id);
      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  async componentDidMount() {
    if (this.props.params.id) {
      let appState = JSON.parse(localStorage.getItem('appState'));

     if (appState) {
       appState.StepLenght = 4;

       this.setState(appState);
       this.CompletarStepAll();
      }
    }

    if (this.props.params.idcobertura) {
      this.PeticionGetHome(TodoDataService.GetHostRestApi() + "/Estados/1", 'DataEstado');
      this.SeleccionarSet(this.props.params.idcobertura);
    }
    else {
      this.GetPeticionToken();
    }

  }

  OnClickBarraProgreso = (item) => {
    this.setState({ StepLenght: item });
  }

  setData = (data) => {
    this.setState(
      data
    );
  }


  // Función para enviar un mensaje con el estado actual
  EnviarMensaje = () => {
    localStorage.setItem('appState', JSON.stringify(this.state));
  };



  render() {
    console.log(this.state);
    let { Route } = this.state;
    return (
      <Container sx={{ marginTop: '48px', padding: '0px 16px !important', maxWidth: '1112px !important' }}>
        {Route && (
          <Navigate to={Route} replace={true} />
        )}
        {

          this.state.Cobertura === false
            ?
            this.props.params.id
              ?
              <></>
              :
              <section>
                <div>
                  <div className="row">
                    <div className="col-md-12 ">
                      <div className="row">
                        <div className="col-xs-8 col-sm-6 ">
                          <Typography mb='0px' variant="h1" sx={{ color: '#d14081', fontWeight: '600 !important', fontSize: '40px' }}>Selecciona tu set funerario</Typography>
                        </div>
                        <div style={{ position: 'relative' }} className="col-xs-4 col-sm-6">
                          <span className="breadcrum">
                          </span>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <Typography mb='48px' sx={{ fontSize: '15px', fontWeight: '400px', color: '#777777' }}>
                            Aquí podrás elegir el servicio funerario inmediato que más se adapte a tus necesidades.
                          </Typography>
                        </div>
                      </div>
                    </div>
                  </div>
                  <StyleDragArea>
                    <div className="row  justify-content-center mr-1" style={{ margin: '0px' }}>
                      {
                        ListSets.map((Set) => (
                          <div onClick={() => { this.PeticionGet(TodoDataService.GetHostRestApi() + "/Estados/1", 'DataEstado'); this.SeleccionarSet(Set.id) }} style={{ cursor: 'pointer', width: 'auto' }} key={'per' + Set.id} className="col-sm-4 mb-4 containerset">
                            <div className=".set card containerset" style={{ width: '20rem' }}>
                              <div className="card-header pt-3 pb-3">
                                <h4 className="card-title set">{Set.nombre}</h4>
                                <h4 className="subset">$ {Set.precio} MX</h4>
                              </div>
                              <div className="card-body mt-3">
                                <h6 className="beneficios">Beneficios</h6>
                                {Set.beneficios.map((beneficio, index) => (<p key={index + 'per' + Set.id} className="listbeneficios">{beneficio}</p>))
                                }
                              </div>
                              <div className="card-footer mb-2">
                                <button style={{ width: '80%' }} onClick={() => { this.PeticionGet(TodoDataService.GetHostRestApi() + "/Estados/1", 'DataEstado'); this.SeleccionarSet(Set.id) }} type="button" className="btn btn_rosa mt-2"  >Contratar ahora</button>
                              </div>
                            </div>
                          </div>
                        ))
                      }
                    </div >
                  </StyleDragArea>
                </div >

              </section >
            :
            this.state.PerzonalizaSets === false
              ?
              <CoberturaSets CallNumber={TodoDataService.GetNumberCall()} OnClickClose={() => (this.setState({ Route: '/' }))} SwitchHandleChange={this.SwitchHandleChange} OnClickPersonaliza={this.ShowPersonalizaSets} DataEstado={this.state.DataEstado} HandleChange={this.handleChange} DataMunicipio={this.state.DataMunicipio} DataColonia={this.state.DataColonia} Token={this.state.Token} OnChange={this.HandleChangeCobertura} FormCobertura={this.state.formCobertura} ModalConsultarCodigopostal={this.state.ModalConsultarCodigoPostal} ShowModalConsultarCodigoPostal={this.ShowModalConsultarCodigoPostal} />
              :
              < PersonalizaSets CallNumber={TodoDataService.GetNumberCall()} ActualizarMarginFoother={this.props.ActualizarMarginFoother} OnClickBarraProgreso={this.OnClickBarraProgreso}
                Step_Datos={{FormCobertura:this.state.formCobertura, OnClickGenerarCliente: TodoDataService.PostGenerarCliente, OnChangeCobertura: this.HandleChangeCoberturaFinado, OnChange: this.handleChange, ListEstados: this.state.DataEstado, ListMunicipios: this.state.DataMunicipio, ListColonias: this.state.DataColonia, FormFinado: this.state.FormFinado, FormResponsable: this.state.FormResponsable, }}
                Step_PersonalizaSet={{ EliminarCarrito: this.EliminarCarrito, ProductoSelecionado: this.state.ProductoSelecionado, OnClickShowAgregarCarrito: this.OnClickShowAgregarCarrito, ShowAgregarCarrito: this.state.ShowAgregarCarrito, OnClickAgregarCarrito: this.AgregarCarrito, ListProductos: this.state.ListProductos, ListGrupos: this.state.ListGrupos, ListCarrito: this.state.ListCarrito, SeleccionarDespedida: this.SeleccionarDespedida }}
                Step_Extra={{ EliminarCarritoExtra: this.EliminarCarritoExtra, AgregarCarritoExtra: this.AgregarCarritoExtra, ShowAgregarCarritoExtra: this.state.ShowAgregarCarritoExtra, ProductoSelecionadoExtra: this.state.ProductoSelecionadoExtra, OnClickShowAgregarCarritoExtra: this.OnClickShowAgregarCarritoExtra, CargarProductosExtras: this.PeticionProductosExtrasGet, ListProductosExtras: this.state.ListProductosExtra, ListGruposExtras: this.state.ListGruposExtra, ListCarritoExtras: this.state.ListCarritoExtra }}
                Step_Pago={{ SetSeleccionado:this.state.SetSelecionado, FormFinado: this.state.FormFinado, FormResponsable: this.state.FormResponsable, OnClickGuardar: TodoDataService.PostNuevoservicio, DataValidarCupon: { Url: UrlValidarCupon, Token: this.state.Token, Data: this.state.FormPago, SetError: this.setData }, OnClickValidarCupon: TodoDataService.ValidarCupon, FormPago: this.state.FormPago, OnChange: this.handleChange, HandleChangeSingle: this.handleChangeSingle }}

                TotalServicioDespedida={this.state.TotalServicioDespedida} HandleNext={this.handleNext} HandleBack={this.handleBack} StepLenght={this.state.StepLenght} ListHeadersStep={this.state.ListHeadersStep} Token={this.state.Token} OnclickReturn={() => { this.ClosePersonalizarSet(); this.props.OcultarFoother(); }} SubTotal={this.state.SubTotal} SubTotalExtra={this.state.SubTotalExtra} SwitchHandleChange={this.SwitchHandleChange} DataSets={ListSets} SetSeleccionado={this.state.SetSelecionado} FormActualizar={this.state.form} OcultarFoother={this.props.OcultarFoother}
                DetalleSetsFuenerarios={this.state.DetalleSetsFuenerarios} DataDetalleSetsFuenerarios={this.state.DataDetalleDetalleSetsFuenerarios} EnviarMensaje={this.EnviarMensaje} />
        }
      </Container>

    );
  }
}


// Se exporta el componente con withRouter para acceder a las props de enrutamiento
export default withRouter(SetsFunerarios);


