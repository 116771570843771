// -----------Importar Componentes de Terceros---------------------
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';


const NotaVenta = ({HorizontalLinearStepper,StyleDragArea,ListHeadersStep,StepLenght,handleClick,formatearComoMonedaMXN,Step_Pago,SubTotalExtra,SubTotal,Step_Extra,Step_PersonalizaSet,TotalServicioDespedida,ListTypo,FormActualizar,SetNombreSeleccionado}) =>{
    return(
        <div className="row" >
        <Box mt='24px' className="col-sm-12" sx={{ display: { xs: 'none', md: 'block' } }}>
          {/* <HorizontalLinearStepper FontSize={'0.85rem'} Steps={ListHeadersStep} NoLabel={false} ActiveStep={StepLenght} /> */}
        </Box>
        <div style={{ margin: ' 0%', padding: '0%', borderRadius: '10px', backgroundColor: 'white', border: '3px solid', borderColor: '#D14081' }} className="col-md-12 row">
          <div style={{ marginTop: '15px' }} className='col-md-9'>
            <Typography display="inline" sx={{ fontWeight: 'bold !important' }}>Servicio:</Typography> <Typography display="inline" sx={{ color: '#D14081', fontWeight: 'bold !important' }}>{SetNombreSeleccionado}, {ListTypo.find((element) => (element.id === FormActualizar.TipoDespedida)).Nombre}</Typography>
          </div>
          <div style={{ marginTop: '15px', display: 'flex', justifyContent: 'flex-end' }} className='col-md-3'>
            <Typography display="inline" sx={{ fontWeight: 'bold !important' }}>{formatearComoMonedaMXN(TotalServicioDespedida)}</Typography>
          </div>
          <div style={{ marginTop: '15px', display: 'flex', justifyContent: 'space-between' }} className='col-md-12'>
            <Typography display="inline" sx={{ fontWeight: 'bold !important' }}>Productos</Typography>   <Typography display="inline" sx={{ fontWeight: 'bold !important' }}>{formatearComoMonedaMXN(SubTotal - TotalServicioDespedida)}</Typography>
          </div>
          <ul>
            {
              Step_PersonalizaSet.ListCarrito.map((element, index) => {
                let cantidadASumar = 0;
                const productoEncontrado = Step_PersonalizaSet.ListProductos.find((producto) => (producto.producto === element.producto && producto.grupo === element.grupo));
                if (productoEncontrado && productoEncontrado.valor_default === 1) {
                } else {
                  const productosEnMismoGrupo = Step_PersonalizaSet.ListProductos.filter((producto) => (producto.grupo === element.grupo && producto.valor_default === 1));
                  cantidadASumar = element.precio_venta - productosEnMismoGrupo[0].precio_venta;
                }
                return (
                  <li style={{ margin: '0px 0px 0px 30px' }} key={index}>
                    <div div className='col-md-12' style={{ margin: '0% 0%', display: 'flex', justifyContent: 'space-between' }} key={index}>
                      <Typography className={cantidadASumar !== 0 ? "c_rosa" : "c_negro"} display="inline" sx={{ fontSize: '12px' }}> {element.nombre_prodcto}</Typography>   <Typography className={cantidadASumar !== 0 ? "c_rosa" : "c_negro"} display="inline" sx={{ fontSize: '12px' }}>{formatearComoMonedaMXN(cantidadASumar !== 0 ? cantidadASumar : 0)}</Typography>
                    </div>
                  </li>

                )
              })
            }
          </ul>



          <div style={{ marginTop: '15px', display: 'flex', justifyContent: 'space-between' }} className='col-md-12'>
            <Typography display="inline" sx={{ fontWeight: 'bold !important' }}>Servicios</Typography>   <Typography display="inline" sx={{ fontWeight: 'bold !important' }}>{formatearComoMonedaMXN(0)}</Typography>
          </div>
          <ul>
            <li style={{ margin: '0px 0px 0px 30px' }}>
              <div div className='col-md-12' style={{ margin: '0% 0%', display: 'flex', justifyContent: 'space-between' }} >
                <Typography className="c_negro" display="inline" sx={{ fontSize: '12px' }}> Traslado de 50 km redondos</Typography>   <Typography className="c_negro" display="inline" sx={{ fontSize: '12px' }}>{formatearComoMonedaMXN(0)}</Typography>
              </div>
            </li>
            <li style={{ margin: '0px 0px 0px 30px' }}>
              <div div className='col-md-12' style={{ margin: '0% 0%', display: 'flex', justifyContent: 'space-between' }} >
                <Typography className="c_negro" display="inline" sx={{ fontSize: '12px' }}> Trámites legales</Typography>   <Typography className="c_negro" display="inline" sx={{ fontSize: '12px' }}>{formatearComoMonedaMXN(0)}</Typography>
              </div>
            </li>
            <li style={{ margin: '0px 0px 0px 30px' }}>
              <div div className='col-md-12' style={{ margin: '0% 0%', display: 'flex', justifyContent: 'space-between' }} >
                <Typography className="c_negro" display="inline" sx={{ fontSize: '12px' }}>Preservación</Typography>   <Typography className="c_negro" display="inline" sx={{ fontSize: '12px' }}>{formatearComoMonedaMXN(0)}</Typography>
              </div>
            </li>
            <li style={{ margin: '0px 0px 0px 30px' }}>
              <div div className='col-md-12' style={{ margin: '0% 0%', display: 'flex', justifyContent: 'space-between' }} >
                <Typography className="c_negro" display="inline" sx={{ fontSize: '12px' }}> Conmemoración</Typography>   <Typography className="c_negro" display="inline" sx={{ fontSize: '12px' }}>{formatearComoMonedaMXN(0)}</Typography>
              </div>
            </li>
            <li style={{ margin: '0px 0px 0px 30px' }}>
              <div div className='col-md-12' style={{ margin: '0% 0%', display: 'flex', justifyContent: 'space-between' }} >
                <Typography className="c_negro" display="inline" sx={{ fontSize: '12px' }}> Cremación</Typography>   <Typography className="c_negro" display="inline" sx={{ fontSize: '12px' }}>{formatearComoMonedaMXN(0)}</Typography>
              </div>
            </li>

          </ul>

          <div style={{ marginBottom: '5px', marginTop: '15px', display: 'flex', justifyContent: 'space-between' }} className='col-md-12'>
            <Typography display="inline" sx={{ fontWeight: 'bold !important' }}>Extras</Typography>   <Typography display="inline" sx={{ fontWeight: 'bold !important' }}>{formatearComoMonedaMXN(SubTotalExtra)}</Typography>
          </div>

          <ul>
            {
              Step_Extra.ListCarritoExtras.map((element, index) => (
                <li style={{ margin: '0px 0px 0px 30px' }} key={index}>
                  <div className='col-md-12' style={{ margin: '0% 0%', display: 'flex', justifyContent: 'space-between' }} key={index}>
                    <Typography display="inline" sx={{ fontSize: '12px' }}>{element.cantidad}  {element.nombre_prodcto}</Typography>   <Typography display="inline" sx={{ fontSize: '12px' }}>{formatearComoMonedaMXN(element.precio_venta * element.cantidad)}</Typography>
                  </div>
                </li>

              ))
            }
          </ul>

          <div style={{ marginBottom: '5px', marginTop: '15px', display: 'flex', justifyContent: 'space-between' }} className='col-md-12'>
            <Typography display="inline" sx={{ fontWeight: 'bold !important' }}>Descuentos</Typography>   <Typography display="inline" sx={{ fontWeight: 'bold !important' }}>-{formatearComoMonedaMXN(Step_Pago.FormPago.Descuento)}</Typography>
          </div>

          {
            Step_Pago.FormPago.FolioCupon == ''
            ?<></>
            :
            <ul>
            <li style={{ margin: '0px 0px 0px 30px' }}>
              <div div className='col-md-12' style={{ margin: '0% 0%', display: 'flex', justifyContent: 'space-between' }} >
                <Typography className="c_rosa" display="inline" sx={{ fontSize: '12px' }}>{Step_Pago.FormPago.FolioCupon}</Typography>   <Typography className="c_negro" display="inline" sx={{ fontSize: '12px' }}>-{formatearComoMonedaMXN(Step_Pago.FormPago.Descuento)}</Typography>
              </div>
            </li>
          </ul>
          }

          



          <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px', padding: '5px', borderColor: '#D14081', borderTop: '3px solid #D14081' }}>
            <Typography sx={{ fontWeight: 'bold !important' }} >Total: {formatearComoMonedaMXN(SubTotalExtra + SubTotal - Step_Pago.FormPago.Descuento)} </Typography>
          </div>
        </div>

        <Box sx={{ width: '100%', marginTop: '25px', display: 'flex', justifyContent: { xs: 'center', sm: 'flex-end' } }}>
          <StyleDragArea>
            <button type="button" style={{ width: '300px', backgroundColor: '#D14081 !important', borderColor: '#D14081 !important' }} onClick={() => (handleClick('/Conmemoraciones'))} className="btn btn_rosa" id="hola" >Acceder a la conmemoración</button>
          </StyleDragArea>
        </Box>
      </div>
    );
}


export default NotaVenta;